.gallery {

}

#gallery-caption,
#gallery-date {
  margin: 0;
}

#galleryCarousel {
  .carousel-indicators {
    padding: 1rem;
    text-align: center;
    margin-right: 12.5%;
    margin-left: 12.5%;
    margin-top: 0;
    margin-bottom: 2.5rem;
    display: none;
    .active {
      background-color: $black;
    }

    @include media-breakpoint-up(md) {
      display: block;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 5rem;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 6rem;
    }

    .fadein {
      @extend .blur;
      padding: 2rem 1rem;
      transition-delay: .25s;
      border-radius: 1rem;
    }

    .fadein:hover {
      opacity: 1;
    }

    button {
      width: 1.25rem;
      height: 1.25rem;
      border: none;
      border-radius: 1.25rem;
      margin: .25rem;
      @include media-breakpoint-up(lg) {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 1.5rem;
        margin: .375rem;
      }
      @include media-breakpoint-up(xl) {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 1.5rem;
        margin: .5rem;
      }
    }
  }

  .carousel-inner {
    max-height: 75vh;

    .carousel-item {
      height: auto;

      img {
        height: 75vh;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
}
