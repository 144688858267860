.hover-fade {
  opacity: .75;
  transition-duration: 0.5s;

  &:hover {
    opacity: .17;
    color: $light;
  }

  &:active {
    opacity: 0;
    color: $light;
  }
}
