@supports ((backdrop-filter: blur(1rem)) or (-webkit-backdrop-filter: blur(1em))) {
  .blur {
    backdrop-filter: blur(1em);
    -webkit-backdrop-filter: blur(1em);
  }
}

@supports (not (backdrop-filter: blur(1rem))) and (not (-webkit-backdrop-filter: blur(1em))) {
  .blur {
    background-color: rgba($modal-backdrop-bg-fallback, .75);
  }
}

.text-blur {
  color: transparent;
  text-shadow: 0 0 .15rem rgba($white, 0.75);
}