.navbar-dark .navbar-nav .nav-link {
  color: $white;
}

.light-shade {
  @extend .container-fluid;
  @extend .position-absolute;
}

@supports ((backdrop-filter: blur(1rem)) or (-webkit-backdrop-filter: blur(1em))) {
  .light-shade {
    backdrop-filter: blur(1rem) !important;
    -webkit-backdrop-filter: blur(1rem) !important;
    //background: black;
    background: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
    z-index: -1;
  }
}

@supports (not (backdrop-filter: blur(1rem))) and (not (-webkit-backdrop-filter: blur(1em))) {
  nav#nav, footer {
    background: rgba($modal-backdrop-bg-fallback, .75);
  }
  .light-shade {
    background: inherit;
    position: relative;
    z-index: -1;
  }
}

nav.navbar {
  height: $top-nav-height;
  padding-top: 0;
  padding-bottom: 0;

  .light-shade {
    height: $top-nav-height;
  }

  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
  }
}

footer.navbar {
  height: $bottom-nav-height;
  .light-shade {
    height: $bottom-nav-height;
  }
}


@include media-breakpoint-up(xl) {
  header.navbar {
    .navbar-collapse {
      height: $top-nav-height;
    }
  }
}

footer.navbar {
  padding-top: 8px;
  padding-bottom: 8px;
}

.nav-link {
  &.link-hidden {
    color: transparent !important;
    &:hover {
      color: $light !important;
    }
  }
}

