.thumbnails.image_picker_selector {
  height: 11rem;
  overflow-y: scroll;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.thumbnails.image_picker_selector:hover {
  //height: 256px;
}

.thumbnails.image_picker_selector .thumbnail {
  margin-bottom: 0;
}

.thumbnails.image_picker_selector li img {
  width: 8rem;
  height: 8rem;
}

ul.thumbnails.image_picker_selector {
  overflow: auto;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;

  border: 1px solid darken(mix($light, $black), 20%);
  background-color: $light;
  align-items: center;
  padding: 1rem;
  border-radius: .25rem;
}

ul.thumbnails.image_picker_selector ul {
  overflow: auto;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.thumbnails.image_picker_selector li.group {
  width: 100%;
}

ul.thumbnails.image_picker_selector li.group_title {
  float: none;
}

ul.thumbnails.image_picker_selector li {
  margin: 0 .5rem .5rem 0;
  float: left;
}

ul.thumbnails.image_picker_selector li .thumbnail {
  padding: .5rem;
  border: 1px solid darken($light, 10%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

ul.thumbnails.image_picker_selector li .thumbnail img {
  -webkit-user-drag: none;
}

ul.thumbnails.image_picker_selector li .thumbnail.selected {
  background: $dark;
}
