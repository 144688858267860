.table-responsive {
  padding-bottom: 7rem;
}

/*
table.table {
  tr.child-0 {
    background-color: #070809;
  }
  tr.child-1 {
    background-color: #0B0D0E;
  }
  tr.child-2 {
    background-color: #101314;
  }
  tr.child-3 {
    background-color: #141719;
  }
  tr.child-4 {
    background-color: #191C1F;
  }
}
*/
