//
// Theming
//

/*
// primary
$hsl-primary-h: 45;
$hsl-primary-s: 64%;
$hsl-primary-l: 58%;

// secondary
$hsl-secondary-h: 45;
$hsl-secondary-s: 64%;
$hsl-secondary-l: 88%;

// dark
$hsl-dark-h:  240;
$hsl-dark-s:  5%;
$hsl-dark-l:  10%;

// light
$hsl-light-h:       $hsl-primary-h;
$hsl-light-s:       10%;
$hsl-light-l:       50%;

$hsl-disabled-h:    $hsl-primary-h;
$hsl-disabled-s:    10%;
$hsl-disabled-l:    40%;

$primary:           hsl($hsl-primary-h, $hsl-primary-s, $hsl-primary-l);
$secondary:         hsl($hsl-secondary-h, $hsl-secondary-s, $hsl-secondary-l);
$light:             hsl($hsl-light-h, $hsl-light-s, $hsl-light-l);
$dark:              hsl($hsl-dark-h, $hsl-dark-s, $hsl-dark-l);

$text-muted:        hsl($hsl-disabled-h, $hsl-disabled-s, $hsl-disabled-l);

$input-bg:            mix($dark, #000);
$input-color:         $light;
$input-border-color:  mix($light, #000);

$link-color: #f8f9fa;

$body-bg: $dark;

*/