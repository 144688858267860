.console-container {
  z-index: 1;
  position: absolute;
  text-align: left;
  bottom: 3.5rem;
  right: 1rem;
  left: 1rem;
  height: auto;
  color: $white;
  text-shadow: 0.075rem 0.075rem 0.15rem $black;

  @include media-breakpoint-up(md) {
    bottom: 4rem;
    right: 8%;
    left: 8%;
  }

  @include media-breakpoint-up(lg) {
    bottom: 4.5rem;
    right: 12.5%;
    left: 12.5%;
  }

  @include media-breakpoint-up(xl) {
    bottom: 5rem;
    right: 15%;
    left: 15%;
  }

  @media screen and (min-width: 1600px) {
    max-width: 1320px;
    left: calc(50% - 660px);
  }

  span {
    font-size: $font-size-base;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      font-size: $font-size-base * 1.25;
    }

    @include media-breakpoint-up(lg) {
      font-size: $font-size-base * 1.5;
    }
  }
}

@supports ((backdrop-filter: blur(1rem)) or (-webkit-backdrop-filter: blur(1em))) {
  .console-container {
    background: rgba($modal-backdrop-bg, .25);
  }
}

@supports (not (backdrop-filter: blur(1rem))) and (not (-webkit-backdrop-filter: blur(1em))) {
  .console-container {
    background-color: rgba($modal-backdrop-bg-fallback, .5);
  }
}

.console-underscore {
  font-size: $font-size-base * 1.25;
  font-weight: 300;
  display:inline-block;
  position:relative;
  left: - $font-size-base * 0.25;
  top: - $font-size-base * 0.06125;
  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 1.5;
    top: - $font-size-base * 1.5 * 0.06125;
  }
  @include media-breakpoint-up(lg) {
    font-size: $font-size-base * 1.75;
    top: - $font-size-base * 1.75 * 0.06125;
  }

  &.hidden {
    opacity:0;
  }
}