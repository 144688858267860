@include media-breakpoint-down(xl) {
  .offcanvas-collapse {
    position: fixed;
    z-index: 1060;
    top: $top-nav-height; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    //background-color: $black;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
    .nav-link {
      font-size: 1.15rem;
      @include media-breakpoint-up(sm) {
        font-size: 1.25rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.75rem;
      }
    }
  }
}


/*
@media (max-width: 991.98px) {
  .offcanvas-collapse {
    z-index: 1060;
    position: fixed;
    top: 56px;
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
  }
  .offcanvas-collapse.show {
    visibility: visible;
    transform: translateX(-100%);
  }
  body.offcanvas-open .custom-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1029;
    width: 100vw;
    height: 100vh;
  }
}
*/


/*
.offcanvas-backdrop.fade.show {
  visibility: visible;
  @include media-breakpoint-up(xl) {
    visibility: hidden;
  }
}

.offcanvas-collapse {
  transform: translateX(0%);
  visibility: visible;
  @include media-breakpoint-down(xl) {
    z-index: 1060;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    &.show {
      visibility: visible;
      transform: translateX(-100%);
    }
    transition: transform .3s ease-in-out;
  }
}
*/

/*
@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    //background-color: $dark;
    transition: transform .3s ease-in-out;

    &.show {
      visibility: visible;
      transform: translateX(-100%);
      .nav-link {
        font-size: 1.15rem;

        @include media-breakpoint-up(sm) {
          font-size: 1.5rem;
        }
        @include media-breakpoint-up(md) {
          font-size: 1.75rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: 2rem;
        }
      }
    }

  }
}



body.offcanvas-open .custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1029;
  width: 100vw;
  height: 100vh;
}

.offcanvas-collapse {
  z-index: 1060;
  height: 100vh;

  .nav-link {
    font-size: 1em;
    transition: font-size 1s ease;
  }
}
*/