.form-floating {
  label {
    color: $text-muted !important;
  }
}

textarea.form-control {
  height: 50vh !important;
}

form {
  .help-text {
    text-align: right;
  }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: $input-bg !important;
  background-color: $input-bg !important;
}