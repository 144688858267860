body.intro {
  position: relative;
  overflow: hidden;

  .video-background{
    width: 100vw !important;
    height: 100vh !important;
    pointer-events: none;

    iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 100vh;
      transform: translate(-50%, -50%);
    }
  }

  #muteButton {
    position: absolute;
    top:.25rem;
    right:.25rem;
  }

  #introLogo {
    position: absolute;
    top:.5rem;
    left:.5rem;
  }

  .button-container {
    position: absolute;
    bottom: 15%;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 1;
  }

}




@media (min-aspect-ratio: 16/9) {
  body.intro .video-background iframe {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 56.25vw;
  }
}

@media (max-aspect-ratio: 16/9) {
  body.intro .video-background iframe {
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 177.78vh;
  }
}

