html, body {
  height: 100%;
}

div#content {
  padding-top: $top-nav-height;
  padding-bottom: 5rem;
  &.home,
  &.intro,
  &.error {
    height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
  }
}