.card-img-overlay {
  cursor: pointer;
  border-radius: 0;

  h5, h6 {
    line-height: 1.25rem;
  }

  .icon {
    bottom: .5rem;
    right: 1rem;
    font-size: 1.5rem;
    &.icon-mixcloud {
      color: #4C588A;
    }
    &.icon-soundcloud {
      color: #F64E00;
    }
    &.icon-youtube {
      color: #F61200;
    }
    &.icon-vimeo {
      color: #43B9F1;
    }
  }

  .date {
    bottom: 1rem;
    left: 1rem;
  }

}