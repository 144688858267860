// customized bootstrap
@import "bootstrap/bootstrap";

// icons
@import "bootstrap-icons";
@import "~socicon/css/socicon.css";

// variables
@import "custom/variables";

// bootstrap components
@import "custom/carousel";

// jquery plugins
//@import "~image-picker/image-picker/image-picker.css";
@import "custom/image-picker";

// ux
@import "custom/dropzone";
@import "custom/typed";

// other
@import "custom/anchor";
@import "custom/backgrounds";
@import "custom/breadcrumbs";
@import "custom/blockquote";
@import "custom/blur";
@import "custom/brightness";
@import "custom/card";
@import "custom/code";
@import "custom/colors";
@import "custom/cookieconsent";
@import "custom/flash-messages";
@import "custom/footer";

// gold
//@import "custom/fonts";
@import "custom/form";
@import "custom/gallery";

// gold
//@import "custom/gold";
@import "custom/infinite";
@import "custom/layout";
@import "custom/markdown";
@import "custom/modal";
@import "custom/navbar";
@import "custom/offcanvas";
@import "custom/opacity";
@import "custom/ratios";
@import "custom/table";
@import "custom/timeline";
@import "custom/video-background";

//@import "custom/custom";


