.bg-january {
  background-color: mix(#a4bcb9, $black);
}
.bg-february {
  background-color: mix(#5f956d, $black);
}
.bg-march {
  background-color: mix(#3a691d, $black);
}
.bg-april {
  background-color: mix(#86a61c, $black);
}
.bg-may {
  background-color: mix(#e8c811, $black);
}
.bg-june {
  background-color: mix(#ff963a, $black);
}
.bg-july {
  background-color: mix(#fa6114, $black);
}
.bg-august {
  background-color: mix(#dc3009, $black);
}
.bg-september {
  background-color: mix(#b1130b, $black);
}
.bg-october {
  background-color: mix(#c25d5b, $black);
}
.bg-november {
  background-color: mix(#d7a3a5, $black);
}
.bg-december {
  background-color: mix(#dbe9f7, $black);
}
