.text-mixcloud {
  color: #4C588A;
}
.text-soundcloud {
  color: #F64E00;
}
.text-youtube {
  color: #F61200;
}
.text-vimeo {
  color: #43B9F1;
}
.text-residentadvisor {
  color: #777;
}
.text-facebook {
  color: #3874E1;
}
.text-discogs {
  color: #555;
}
.text-myspace {
  color: #666;
}
