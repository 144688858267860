.dropzone-container {
  position: relative;
  display: flex;
  height: 10rem;
  border: 1px solid darken(mix($light, $black), 20%);
  background-color: $light;
  align-items: center;
  padding: 1rem;
  border-radius: .25rem;
}

.dropzone-input {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.dropzone-preview {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.dropzone-preview-image {
  flex-basis: 0;
  min-width: 8rem;
  max-width: 8rem;
  height: 8rem;
  margin-right: 1rem;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.dropzone-preview-filename {
  word-wrap: anywhere;
  color: $dark;
}

.dropzone-preview-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: $text-muted;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

.dropzone-preview-button::before {
  content: '×';
  padding: .5rem .75rem 0 0;
  cursor: pointer;
  font-size: 2rem;
}

.dropzone-placeholder {
  flex-grow: 1;
  text-align: center;
  color: $text-muted;
}
