.container.flash {
  &:first-child {
    margin-top: calc(56px + 1rem);
  }
  &:nth-child {
    margin-bottom: .5rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
}