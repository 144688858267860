.single-timeline-area {
  .ratio-1x1 {
    --bs-aspect-ratio: 70%;
    @media (min-width: 320px) {
      --bs-aspect-ratio: 50%;
    }
    @media (min-width: 360px) {
      --bs-aspect-ratio: 45%;
    }
    @media (min-width: 400px) {
      --bs-aspect-ratio: 40%;
    }
    @media (min-width: 440px) {
      --bs-aspect-ratio: 33%;
    }
    @include media-breakpoint-up(sm) {
      --bs-aspect-ratio: 80%;
    }
    @media (min-width: 520px) {
      --bs-aspect-ratio: 70%;
    }
    @include media-breakpoint-up(md) {
      --bs-aspect-ratio: 75%;
    }
    @include media-breakpoint-up(lg) {
      --bs-aspect-ratio: 100%; // 1x1
    }
  }
}

.admin,
.profile{
  .ratio-1x1 {
    --bs-aspect-ratio: 100%;
    @media (min-width: 320px) {
      --bs-aspect-ratio: 60%;
    }
    @media (min-width: 360px) {
      --bs-aspect-ratio: 50%;
    }
    @media (min-width: 400px) {
      --bs-aspect-ratio: 40%;
    }
    @media (min-width: 440px) {
      --bs-aspect-ratio: 37.5%;
    }
    @include media-breakpoint-up(sm) {
      --bs-aspect-ratio: 100%; // 1x1
    }
  }
}
