.carousel-item {
  height: 100vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel {
  .carousel-caption {
    bottom: 5rem;
    color: $white;
    text-shadow: 0.075rem 0.075rem 0.15rem $black;

    h5 {
      font-size: $font-size-sm;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
      }

      @include media-breakpoint-up(lg) {
        font-size: $font-size-lg;
      }
    }
  }
}

.home > .carousel {
  .carousel-caption {
    display: none;
  }
}
