@supports ((backdrop-filter: blur(1rem)) or (-webkit-backdrop-filter: blur(1em))) {
  .custom-backdrop {
    @include overlay-backdrop(1029, $modal-backdrop-bg, $modal-backdrop-opacity);
  }
}

@supports (not (backdrop-filter: blur(1rem))) and (not (-webkit-backdrop-filter: blur(1em))) {
  .custom-backdrop {
    @include overlay-backdrop-fallback(1029, $modal-backdrop-bg-fallback, $modal-backdrop-opacity-fallback);
  }
}

.modal-open { overflow: hidden; }



