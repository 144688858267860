footer {
  a {
    &:hover {
      i {
        transition-duration: 0.5s;
        &.socicon-soundcloud {
          color: #F64E00;
        }
        &.socicon-residentadvisor {
          color: #777;
        }
        &.socicon-bandcamp {
          color: #ccc;
        }
        &.bi-facebook {
          color: #3874E1;
        }
        &.bi-vinyl-fill {
          color: #555;
        }
      }
    }

  }

  .copyright {
    a {
      text-decoration: none;
      font-size: $small-font-size;
    }
  }
}
