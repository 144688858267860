.timeline_area {
  position: relative;
}

.single-timeline-area {
  position: relative;
  padding-left: 2.5em;
}

.single-timeline-area {

  &:not(:last-of-type) {
    margin-bottom: 1.5em;
  }

  .timeline-date {
    position: absolute;
    width: 3em;
    height: 100%;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: center;
    padding-right: 3em;

    p {
      transform: rotate(-90deg);
    }

    &::after {
      position: absolute;
      width: 2px;
      height: 100%;
      content: "";
      background-color: #ccc;
      top: 0;
      right: 30px;
    }

    &::before {
      position: absolute;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: #ccc;
      content: "";
      top: 50%;
      right: 26px;
      z-index: 5;
      margin-top: -5.5px;
    }

    p {
      margin-bottom: 0;
      color: #ccc;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .card {
    cursor: pointer;
  }

  .sheet {
    min-height: 100%;
    //background-color: #ccc;
    color: black;
    border: 0;
    line-height: .9em;
    padding: .75em 1.5em;
    text-align: center;
  }

  .single-timeline-content {
    position: relative;
    border-radius: 6px;
    margin-bottom: 15px;
    margin-top: 15px;
    -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    border: 1px solid #ebebeb;

    .timeline-icon {
      -webkit-transition-duration: 500ms;
      transition-duration: 500ms;
      width: 30px;
      height: 30px;
      background-color: #f1c40f;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 30px;
      flex: 0 0 30px;
      text-align: center;
      max-width: 30px;
      border-radius: 50%;
      margin-right: 15px;

      i {
        color: #ffffff;
        line-height: 30px;
      }
    }

    .timeline-sheet {
      background: #ccc;
      color: black;
      border: 0;
      line-height: .9em;
      padding: 8px;
      float: left;
      margin-right: 10px;
      text-align: center;

      .day, .month, .year {
        display: block;
        clear: both;
      }

      .day {
        font-size: 1.6em;
      }

      .month {
        padding-top: .25em;
        padding-bottom: .25em;
        font-size: 1.2em;
      }

      .year {
        font-size: 1em;
      }
    }

    .timeline-text {
      h6 {
        -webkit-transition-duration: 500ms;
        transition-duration: 500ms;
      }

      p {
        font-size: 13px;
        margin-bottom: 0;
      }
    }

    &:hover,
    &:focus {
      .timeline-icon {
        background-color: #020710;
      }
      .timeline-text {
        h6 {
          color: #3f43fd;
        }
      }
    }


  }

}

/*
@media only screen and (max-width: 575px) {
  .single-timeline-area .timeline-date {
    width: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .single-timeline-area .single-timeline-content {
    padding: 20px;
  }
}
*/

