.markdown {
  h1, h2 {
    margin-top: 2.5rem;
  }
  h3, h4 {
    margin-top: 2rem;
  }
  h5, h6 {
    margin-top: 1rem;
  }

  h1:first-of-type,
  h2:first-of-type,
  h3:first-of-type,
  h4:first-of-type,
  h5:first-of-type,
  h6:first-of-type {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: .5rem;
  }
  ul {
  }

  a {
    text-decoration: none;
  }
}

.projects {
  .markdown {
    h1 {
      font-size: 1.125rem;
    }
    h2, h3, h4, h5, h6 {
      font-size: 1rem;
    }
    h3, h4, h5, h6 {
      font-weight: normal;
    }
  }
}