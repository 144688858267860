blockquote {
  background: mix($dark, $black);
  border-left: .5rem solid mix($dark, $light);
  margin: 1.5em 0;
  padding: 0.5rem 0.5rem;

  p {
    margin-bottom: 0 !important;
  }
}
