// Shared between modals and offcanvases
@mixin overlay-backdrop($zindex, $backdrop-bg, $backdrop-opacity) {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(1em);
  -webkit-backdrop-filter: blur(1em);
  overscroll-behavior: contain;

  //background:black;
  //background:rgba($backdrop-bg,0.37);

  //&.fade { opacity: 0; }
  //&.show { opacity: $backdrop-opacity; }

  // Fade for backdrop
  &.fade { background:rgba($backdrop-bg,0); visibility: hidden; }
  &.show { background:rgba($backdrop-bg, $backdrop-opacity); visibility: visible; }
}

// mozilla
@mixin overlay-backdrop-fallback($zindex, $backdrop-bg, $backdrop-opacity) {
  background-color: $backdrop-bg;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex;
  width: 100vw;
  height: 100vh;

  &.fade { opacity: 0; visibility: hidden; }
  &.show { opacity: $backdrop-opacity; visibility: visible; }
}